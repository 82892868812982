//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA79daB_AaMzfC_rjRlbWYch8hwmGgzDRk",
  authDomain: "hanytix-bd5c9.firebaseapp.com",
  projectId: "hanytix-bd5c9",
  storageBucket: "hanytix-bd5c9.appspot.com",
  messagingSenderId: "477960183638",
  appId: "1:477960183638:web:7748a91f6a1b4a247440f7",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
